<template>
  <v-col cols="11" class="section-wrapper">
    <MyImages
      name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631737824/images/klds5r01sykfbucdkumu.svg"
      style="margin-top: 7px; margin-left: 3px"
      class="image-top"
    />
    <div class="section-wrapper-text">
      <Texting
        type="p2 MainDark"
        style="opacity: 0.7; letter-spacing: 0.056px !important"
        >I used to have my entire team on shifts, updating our campaign's perks
        manually every 2h! <br />Now that Perkfection does this automatically
        (as well as a ton of other stuff I didn't know I needed), we're freed up
        to do more important marketing tasks.</Texting
      >
      <Texting
        type="p2-1 MainDark"
        style="opacity: 0.7; margin-top: 40px !important"
        >Ivaiylo Kalburdzhiev</Texting
      >
      <Texting type="label6 grey23"
        >Crowdfunding Consultant, Founder of
        <a
          style="color: #9e33e0; text-decoration: none"
          href="https://dayonefunding.com/"
          >dayonefunding.com</a
        ></Texting
      >
    </div>
  </v-col>
</template>
<script>
import Texting from "@/components/Texting";
import MyImages from "@/components/MyImages";
export default {
  components: { MyImages, Texting },
};
</script>
<style scoped>
.section-wrapper {
  background-color: #fbfbfb;
  box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.15);
  border-radius: 22px;
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1631737846/images/Untitled_design_1_1_dfw6uj.png");
  padding-top: 17px;
  padding-left: 21px;
  margin-top: 56px;
  min-height: 331px;
  background-repeat: no-repeat;
  background-position: right;
  max-width: 832px;
}
.section-wrapper-text {
  padding-left: 91px;
  padding-right: 259px;
}
@media only screen and (max-width: 600px) {
  .section-wrapper {
    background-position: right bottom;
  }
  .image-top {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .section-wrapper-text {
    margin-top: 26px;
    padding-left: 48px !important;
    padding-right: 37px !important;
    margin-bottom: 320px;
  }
}
</style>
