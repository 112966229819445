<template>
  <v-col v-if="type && type !== ''" cols="auto" class="d-flex">
    <div
      v-for="i in 2"
      :key="i"
      @click="onClick(i)"
      :style="{
        width: i === 1 ? '136px' : '96px',
        marginRight: i === 1 ? '40px' : '',
      }"
      :class="{
        pointer:
          !(type === 'live' && i === 1) && !(type === 'indemand' && i === 2),
      }"
    >
      <Texting
        type="p2strong MainDark"
        style="letter-spacing: 0.05em !important"
        class="text-center switch-type-text"
        :style="{
          opacity:
            (type === 'live' && i === 1) || (type === 'indemand' && i === 2)
              ? '1'
              : '.7',
        }"
        >{{ i === 1 ? "Live Campaign" : "InDemand" }}</Texting
      >
      <div
        class="switch-line mt-1"
        :style="{
          backgroundColor:
            (type === 'live' && i === 1) || (type === 'indemand' && i === 2)
              ? '#9E33E0'
              : '#a7a0b8',
          opacity:
            (type === 'live' && i === 1) || (type === 'indemand' && i === 2)
              ? ''
              : '0.4',
        }"
      />
    </div>
  </v-col>
</template>

<script>
import Texting from "@/components/Texting";
import myServices from "@/services/resource";

export default {
  components: { Texting },
  props: {
    type: String,
  },
  data: () => ({}),
  methods: {
    onClick(i) {
      if (i === 1 && this.type === "indemand") {
        this.$emit("onChange", "live");
        myServices.myAnalyticsEvents(this, "Pricing", "Live campaign click");
      }
      if (i === 2 && this.type === "live") {
        this.$emit("onChange", "indemand");
        myServices.myAnalyticsEvents(
          this,
          "Pricing",
          "Indemand campaign click",
        );
      }
    },
  },
};
</script>

<style scoped>
.switch-line {
  width: 100%;
  height: 2px;
  border-radius: 2px;
}
.switch-type-text:hover {
  opacity: 1 !important;
}
</style>
