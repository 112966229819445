<template>
  <v-col
    cols="12"
    :class="{ 'indemand-container': type === 'indemand' }"
    style="padding-top: 54px"
    class="px-6"
  >
    <v-row justify="center" align="center" v-show="type === 'live'">
      <v-col
        v-for="(card, i) in cards.live"
        :key="i"
        cols="4"
        class="pricing-card pointer mb-12 mb-md-0"
        :class="{ 'mx-0 mx-sm-6 middle-card-live': i === 1 }"
        style="padding: 6px; min-width: 258px"
        :style="{
          maxWidth: i === 2 ? '302px' : '258px',
          minWidth: i === 2 ? '302px' : '258px',
        }"
        @click="$emit('open-signup')"
      >
        <div
          class="card-container"
          style="position: relative"
          :class="{
            'card2-background': i === 1,
            'card3-background': i === 2,
            'card1-background': i === 0,
          }"
          :style="{
            padding:
              i === 2 ? '0 49px 39px' : i === 1 ? '0 27px 22px' : '0 27px 33px',
          }"
        >
          <MyImages :name="card.image" class="card-image" />
          <Heading
            type="h4-1"
            class="text-center mb-3"
            :style="{
              color: i === 0 ? '#81C2DE' : i === 1 ? '#62CBBE' : '#A77EEA',
              paddingTop: i === 0 || i === 1 ? '92px' : '108px',
            }"
            >{{ card.titie }}</Heading
          >
          <Texting
            type="p2strong MainDark"
            class="text-center"
            style="
              opacity: 0.8;
              line-height: 21px;
              letter-spacing: 0.04em !important;
            "
            :style="{
              marginBottom: i !== 2 ? '40px !important' : '',
            }"
            >{{ card.description + " " }}<br v-if="i === 2" /><b>{{
              card.days + " "
            }}</b
            >long*</Texting
          >
          <MyImages
            v-if="i === 2"
            class="d-block mx-auto mt-3 mb-4"
            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637532709/images/pricing_best_offer_icon_h6wrj6.svg"
          />
          <div
            class="card-button d-flex justify-center align-end mx-auto"
            :style="{
              backgroundColor:
                i === 0 ? '#83C4E0' : i === 1 ? '#5AC3B6' : '#9F76E2',
            }"
          >
            <Texting type="p3bold mywhite">${{ card.price }}</Texting>
            <Texting type="label7 mywhite" style="padding-bottom: 2px"
              >/day</Texting
            >
          </div>
          <Texting
            type="p1 grey23"
            style="line-height: 14px"
            class="text-center nanum-pen"
            >per campaign</Texting
          >
          <Texting
            v-if="i !== 0"
            type="label7 grey23"
            class="text-center"
            style="opacity: 0.8; position: relative"
            :style="{
              bottom: i === 1 ? '-23px' : '-40px',
            }"
            >{{ card.discount + "% OFF" }}</Texting
          >
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-md-8 pb-5">
        <Texting class="text-center" type="p2n grey23"
          >* You can activate Perkfection for a shorter period than your
          campaign</Texting
        >
      </v-col>
    </v-row>
    <v-row justify="center" align="start" v-show="type === 'indemand'">
      <v-col
        v-for="(card, i) in cards.indemand"
        :key="i"
        cols="3"
        class="pricing-card pointer mb-8 mb-md-0"
        :class="{
          'ml-5': i !== 0,
          'ind-card-2': i === 1,
          'ind-card-3': i === 2,
          'ind-card-4': i === 3,
        }"
        style="padding: 6px"
        :style="{
          maxWidth: i === 2 ? '256px' : '202px',
          minWidth: i === 2 ? '256px' : '202px',
          width: i === 2 ? '256px' : '202px',
        }"
        @click="$emit('open-signup')"
      >
        <div
          class="card-container"
          :class="{
            'card4-background': i === 1 || i === 3,
            'card5-background': i === 2,
            'card1-ind-background': i === 0,
          }"
          :style="{
            padding:
              i === 0
                ? '26px 29px 25px'
                : i === 2
                ? '14px 56px 37px'
                : '36px 29px 25px',
          }"
        >
          <MyImages
            v-if="i === 2"
            class="d-block mx-auto mb-5"
            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637564007/images/pricing_bestseller_icon_fleisb.svg"
          />
          <Heading type="h4-1 MainDark" class="text-center">{{
            card.title
          }}</Heading>
          <Texting
            type="label0 grey23"
            style="margin-top: 2px"
            class="text-center mb-3 nanum-pen"
            >subscription</Texting
          >
          <div
            class="card-button mb-2 d-flex justify-center align-end mx-auto"
            :class="{
              'three-month-button': i === 2,
            }"
            :style="{
              backgroundColor:
                i === 0
                  ? '#64BCE1'
                  : i === 1
                  ? '#5AC3B6'
                  : i === 2
                  ? '#9E33E0'
                  : '#3DCD99',
            }"
          >
            <Texting type="p3bold mywhite">${{ card.price }}</Texting>
            <Texting type="label7 mywhite" style="padding-bottom: 2px"
              >/day</Texting
            >
          </div>
          <Texting type="label0 grey23" class="text-center nanum-pen"
            >per campaign</Texting
          >
          <Texting
            v-if="i !== 0"
            type="label7 package-4-ind-color"
            class="text-center"
            style="opacity: 0.8; position: relative"
            :style="{
              bottom: i === 2 ? '-38px' : '-25px',
            }"
            :class="{
              'three-month-discount': i === 2,
            }"
            >{{ card.discount + "% OFF" }}</Texting
          >
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-md-8 pb-5">
        <Texting
          class="text-center"
          style="font-style: italic"
          type="p2n grey23"
          >** Change your plan or cancel subscription at any time</Texting
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import MyImages from "@/components/MyImages";
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
export default {
  components: { Texting, Heading, MyImages },
  props: {
    type: String,
  },
  data: () => ({
    cards: {
      live: [
        {
          image:
            "https://res.cloudinary.com/dyf7aejih/image/upload/v1637841500/images/Illustration_owvybb.png",
          titie: "Short Ride",
          description: "for live campaigns up to",
          days: "15 days",
          price: "23",
          discount: "",
        },
        {
          image:
            "https://res.cloudinary.com/dyf7aejih/image/upload/v1637841501/images/Illustration_1_tc1tr4.png",
          titie: "Taking Off",
          description: "for live campaigns up to",
          days: "35 days",
          price: "21",
          discount: "9",
        },
        {
          image:
            "https://res.cloudinary.com/dyf7aejih/image/upload/v1637841501/images/Illustration_2_rngypx.png",
          titie: "Long Hauler",
          description: "for live campaigns",
          days: "36+ days",
          price: "18",
          discount: "22",
        },
      ],
      indemand: [
        {
          title: "Weekly",
          price: "20.90",
          discount: "",
        },
        {
          title: "Monthly",
          price: "17.90",
          discount: "14",
        },
        {
          title: "3-Month",
          price: "10.90",
          discount: "48",
        },
        {
          title: "6-Month",
          price: "9.90",
          discount: "53",
        },
      ],
    },
  }),
  methods: {},
};
</script>

<style scoped>
.pricing-card {
  background: #fbfbfb;
  box-shadow: 0px 5.88353px 25.9567px rgba(0, 0, 0, 0.0482987),
    0px 2.85634px 12.6015px rgba(0, 0, 0, 0.0389404),
    0px 1.40023px 6.1775px rgba(0, 0, 0, 0.0310596),
    0px 0.553654px 2.44259px rgba(0, 0, 0, 0.0217013);
  border-radius: 10px;
  position: relative;
}
.pricing-card:hover {
  box-shadow: 0 2px 8px rgb(0 0 0 / 8%), 0 1px 4px rgb(0 0 0 / 8%);
}
.pricing-card:hover .card-image {
  transform: translate(0, -5px);
}
.card-container {
  /*background: #ffffff;*/
  /*border: 1px solid #eadcec;
  border-radius: 6px;*/
  width: 100%;
  height: 100%;
}
.card-container.card1-background {
  background: #ffffff;
  border: 1px solid #eadcec;
  border-radius: 6px;
}
.card-container.card1-ind-background {
  background: #ffffff;
  border: 1px solid #ecf5fc;
  border-radius: 6px;
}
.card2-background {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1637533613/images/pricing_card_background2_zl3xe7.svg");
  background-repeat: no-repeat;
}
.card3-background {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1637536844/images/pricing_card_background3_b3iszq.svg");
  background-repeat: no-repeat;
}
.card4-background {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1637567451/images/pricing_card_background4_rqk8wj.svg");
  background-repeat: no-repeat;
}
.card5-background {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1637567451/images/pricing_card_background5_xg1nqh.svg");
  background-repeat: no-repeat;
}
.card-image {
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  top: -51px;
  display: block;
}
.card-button {
  border-radius: 5px;
  width: 130px;
  padding-top: 5px;
  padding-bottom: 4px;
  margin-bottom: 6px;
}
.three-month-discount {
  background: linear-gradient(
      299.37deg,
      #007ed7 -114.06%,
      rgba(0, 125, 206, 0.53125) 3.82%,
      rgba(0, 166, 255, 0) 85.65%
    ),
    linear-gradient(0deg, #9e33e0, #9e33e0),
    linear-gradient(0deg, #034752, #034752),
    linear-gradient(
      103.15deg,
      #5f57a8 -34.12%,
      rgba(95, 87, 168, 0.820242) -21.41%,
      rgba(95, 87, 168, 0) 36.56%
    ),
    linear-gradient(128.45deg, #27e1af -33.77%, #9b60e7 26.13%, #8015c2 66.41%),
    linear-gradient(0deg, #333333, #333333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.three-month-button {
  box-shadow: 0px 10px 24px rgba(158, 51, 224, 0.24),
    0px 4px 8px rgba(158, 51, 224, 0.24),
    0px 1.46302px 3.51372px rgba(158, 51, 224, 0.198454);
}
.indemand-container {
  padding-bottom: 142px;
}
@media only screen and (max-width: 945px) {
  .indemand-container {
    padding-bottom: 28px;
  }
}
@media only screen and (max-width: 870px) and (min-width: 539px) {
  .middle-card-live {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 924px) and (min-width: 720px) {
  .ind-card-4 {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 450px) {
  .ind-card-4,
  .ind-card-3,
  .ind-card-2 {
    margin-left: 100% !important;
    margin-right: 100% !important;
    display: block;
  }
}
</style>
