<template>
  <v-col
    cols="12"
    md="10"
    class="pt-4 section-wrapper"
    style="max-width: 1328px"
    :style="{ paddingBottom: type === 'live' ? '46px' : '78px' }"
  >
    <v-row justify="center" style="position: relative">
      <v-col cols="auto" class="pt-11">
        <Heading type="h3 MainDark" class="header text-center mb-2"
          >What’s Included</Heading
        >
        <Texting
          type="p2 MainDark"
          style="opacity: 0.8"
          class="text-center mb-10"
          >Start now and get <b>3 days for free</b> for the first
          campaign</Texting
        >
        <div
          v-for="(text, i) in includedTexts"
          class="d-flex pb-2 list-item"
          :style="{ flexWrap: i === 3 ? 'wrap' : '' }"
          :key="i"
        >
          <MyImages
            v-if="
              type === 'live' || (type === 'indemand' && i !== 5 && i !== 6)
            "
            class="mr-3 check-icon"
            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637578995/images/green_check_icon_lpbfhp.svg"
          />
          <Texting
            v-if="
              type === 'live' || (type === 'indemand' && i !== 5 && i !== 6)
            "
            type="p2 MainDark"
            :style="{ whiteSpace: i === 3 ? 'nowrap' : '' }"
            style="opacity: 0.8; display: flex"
            >{{ text }}</Texting
          >

          <div v-if="i === 3" class="d-flex align-center ml-3 perks-group">
            <div class="add-on">
              <Texting type="label1 MainDark" style="white-space: nowrap"
                >Add-on</Texting
              >
            </div>
            <div class="secret mx-1">
              <Texting type="label1 grey5">Secret</Texting>
            </div>
            <div class="featured">
              <Texting type="label1 grey5">Featured</Texting>
            </div>
          </div>
        </div>
        <Button
          :handleClick="openSignUp"
          size="block"
          texting="p3bold mywhite"
          type="primary"
          class="sign-up-button py-5 mb-6"
          :class="{
            'mt-4': type === 'live',
            'mt-8': type === 'indemand',
          }"
          >TRY 3-DAY FREE</Button
        >
        <Texting type="p2full grey23" class="text-center nanum-pen"
          >No credit card required</Texting
        >
      </v-col>
      <div>
        <MyImages
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637581450/images/included_section_left_icon1_lpezon.svg"
          class="d-none d-lg-block"
          style="position: absolute; left: 23px; top: 125px"
        />
        <MyImages
          style="position: absolute; left: 327px; top: 224px"
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637581634/images/included_section_left_icon2_zx2z2c.svg"
          class="d-none d-lg-block"
        />
        <MyImages
          style="position: absolute; left: 17px; z-index: 100"
          :style="{ bottom: type === 'live' ? '-46px' : '-78px' }"
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637582141/images/included_section_left_robot_sd4dfl.svg"
          class="d-none d-lg-block"
        />
        <MyImages
          style="position: absolute; left: -11px; z-index: 10"
          :style="{ bottom: type === 'live' ? '-46px' : '-78px' }"
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637582309/images/included_section_left_robot_bottom_tbo8wm.svg"
          class="d-none d-lg-block"
        />
      </div>
      <MyImages
        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1638224539/images/Frame_487_yaduyt.png"
        class="d-block d-md-none gift-mobile"
      />
      <div class="d-none d-md-block">
        <MyImages
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637583662/images/Group_433_a0o4w1.png"
          class="gift-image"
          style="position: absolute; right: 50px; top: -50px"
        />
        <MyImages
          style="position: absolute; right: 197px; top: 0px"
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637587009/images/included_section_right_icon1_blmix0.svg"
          class="gift-section-left"
        />
        <MyImages
          style="position: absolute; right: -11px; top: -30px"
          name="https://res.cloudinary.com/dyf7aejih/image/upload/v1637587133/images/included_section_right_icon2_udwj0r.svg"
          class="gift-section-right"
        />
      </div>
    </v-row>
  </v-col>
</template>
<script>
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
import MyImages from "@/components/MyImages";
import Button from "@/components/Button";
import myServices from "@/services/resource";
export default {
  components: { MyImages, Texting, Heading, Button },
  props: {
    type: String,
  },
  data: () => ({
    includedTexts: [
      "24/7 Real-Time Monitoring",
      "Automatic Top-ups every 30 seconds",
      "Unlimited Reward Automation",
      "Works with All Rewards",
      "Advanced Daily Reports on Top-ups",
      "24/7 Tracking of Kickstarter Campaign Referral Conversions",
      "Advanced Analytics for Kickstarter Referral Conversions",
    ],
  }),
  methods: {
    openSignUp: function () {
      this.$emit("open-signup");
      myServices.myAnalyticsEvents(this, "Pricing", "CTA button click");
    },
  },
};
</script>
<style scoped>
.header {
  text-shadow: 0px 17px 75px rgba(0, 0, 0, 0.07),
    0px 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0px 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0px 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0px 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}
.section-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.15);
  border-radius: 40px;
}
.add-on {
  background: #eadcec;
  border-radius: 3px;
  padding: 0 5px;
}
.secret {
  background: #333333;
  border-radius: 3px;
  padding: 0 5px;
}
.featured {
  background: #e51075;
  border-radius: 3px;
  padding: 0 5px;
}
.sign-up-button {
  box-shadow: 0px 22px 31px rgba(158, 51, 224, 0.34),
    0px 4.91399px 9.32645px rgba(158, 51, 224, 0.267034),
    0px 1.46302px 3.51372px rgba(158, 51, 224, 0.198454);
}

@media only screen and (max-width: 960px) {
  .section-wrapper {
    border-radius: 0 !important;
    padding-top: 100px !important;
  }
  .sign-up-button {
    max-width: 297px;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .check-icon {
    margin-left: 40px;
  }
  .gift-mobile {
    position: absolute;
    top: -124px;
  }
}

@media only screen and (max-width: 450px) {
  .perks-group {
    margin-left: 66px !important;
    margin-top: 4px;
  }
}
</style>
