var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type && _vm.type !== '')?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto"}},_vm._l((2),function(i){return _c('div',{key:i,class:{
      pointer:
        !(_vm.type === 'live' && i === 1) && !(_vm.type === 'indemand' && i === 2),
    },style:({
      width: i === 1 ? '136px' : '96px',
      marginRight: i === 1 ? '40px' : '',
    }),on:{"click":function($event){return _vm.onClick(i)}}},[_c('Texting',{staticClass:"text-center switch-type-text",staticStyle:{"letter-spacing":"0.05em !important"},style:({
        opacity:
          (_vm.type === 'live' && i === 1) || (_vm.type === 'indemand' && i === 2)
            ? '1'
            : '.7',
      }),attrs:{"type":"p2strong MainDark"}},[_vm._v(_vm._s(i === 1 ? "Live Campaign" : "InDemand"))]),_c('div',{staticClass:"switch-line mt-1",style:({
        backgroundColor:
          (_vm.type === 'live' && i === 1) || (_vm.type === 'indemand' && i === 2)
            ? '#9E33E0'
            : '#a7a0b8',
        opacity:
          (_vm.type === 'live' && i === 1) || (_vm.type === 'indemand' && i === 2)
            ? ''
            : '0.4',
      })})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }