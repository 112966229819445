<template>
  <div>
    <AppHeader />
    <v-container id="pricing-page" fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="12" class="pt-8">
              <Heading type="h2-7 MainDark" class="pricing-header"
                >Increase Your Campaign Conversions Today.
                <span style="color: #9e33e0">Start Free</span></Heading
              >
              <Texting
                type="p3l MainDark"
                class="pt-3 text-center"
                style="opacity: 0.7"
                >Leave the routine work to us, and never miss another top-up
                again!</Texting
              >
            </v-col>
            <SwitchTypes @onChange="setType($event)" :type="type"></SwitchTypes>
            <PricingCards @open-signup="signupActive = true" :type="type" />
            <IncludedSection @open-signup="signupActive = true" :type="type" />
            <div style="width: 100%"></div>
            <Testimonial />
          </v-row>
          <!--          <FAQsection type="pricing" :pricing-type="type" />-->
          <Faq type="pricing" :pricing-type="type" />
          <LoginAndSignUp
            :value="value"
            @closed="signupActive = false"
            :active="signupActive"
            @onsignup-click="value = 'signup'"
            @onlogin-click="value = 'login'"
          />
        </v-col>
      </v-row>
    </v-container>
    <AppFooter />
  </div>
</template>

<script>
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import SwitchTypes from "@/views/PricingPage/SwitchTypes";
import PricingCards from "@/views/PricingPage/PricingCards";
import IncludedSection from "@/views/PricingPage/IncludedSection";
import Testimonial from "@/views/PricingPage/Testimonial";
import LoginAndSignUp from "@/components/LoginAndSignUp";
//import FAQsection from "@/components/FAQsection";
import Faq from "@/components/Faq";
export default {
  components: {
    Faq,
    //FAQsection,
    LoginAndSignUp,
    Testimonial,
    IncludedSection,
    PricingCards,
    SwitchTypes,
    Texting,
    Heading,
    AppHeader,
    AppFooter,
  },
  data: () => ({
    type: "live",
    signupActive: false,
    value: "signup",
  }),
  methods: {
    setType(type) {
      this.type = type;
    },
  },
};
</script>

<style scoped>
.pricing-header {
  text-align: center;
  text-shadow: 0 17px 75px rgba(0, 0, 0, 0.07),
    0 6.20528px 27.3762px rgba(0, 0, 0, 0.0482987),
    0 3.01255px 13.2907px rgba(0, 0, 0, 0.0389404),
    0 1.47681px 6.51533px rgba(0, 0, 0, 0.0310596),
    0 0.583932px 2.57617px rgba(0, 0, 0, 0.0217013);
}
</style>
