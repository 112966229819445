var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-6",class:{ 'indemand-container': _vm.type === 'indemand' },staticStyle:{"padding-top":"54px"},attrs:{"cols":"12"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'live'),expression:"type === 'live'"}],attrs:{"justify":"center","align":"center"}},[_vm._l((_vm.cards.live),function(card,i){return _c('v-col',{key:i,staticClass:"pricing-card pointer mb-12 mb-md-0",class:{ 'mx-0 mx-sm-6 middle-card-live': i === 1 },staticStyle:{"padding":"6px","min-width":"258px"},style:({
        maxWidth: i === 2 ? '302px' : '258px',
        minWidth: i === 2 ? '302px' : '258px',
      }),attrs:{"cols":"4"},on:{"click":function($event){return _vm.$emit('open-signup')}}},[_c('div',{staticClass:"card-container",class:{
          'card2-background': i === 1,
          'card3-background': i === 2,
          'card1-background': i === 0,
        },staticStyle:{"position":"relative"},style:({
          padding:
            i === 2 ? '0 49px 39px' : i === 1 ? '0 27px 22px' : '0 27px 33px',
        })},[_c('MyImages',{staticClass:"card-image",attrs:{"name":card.image}}),_c('Heading',{staticClass:"text-center mb-3",style:({
            color: i === 0 ? '#81C2DE' : i === 1 ? '#62CBBE' : '#A77EEA',
            paddingTop: i === 0 || i === 1 ? '92px' : '108px',
          }),attrs:{"type":"h4-1"}},[_vm._v(_vm._s(card.titie))]),_c('Texting',{staticClass:"text-center",staticStyle:{"opacity":"0.8","line-height":"21px","letter-spacing":"0.04em !important"},style:({
            marginBottom: i !== 2 ? '40px !important' : '',
          }),attrs:{"type":"p2strong MainDark"}},[_vm._v(_vm._s(card.description + " ")),(i === 2)?_c('br'):_vm._e(),_c('b',[_vm._v(_vm._s(card.days + " "))]),_vm._v("long*")]),(i === 2)?_c('MyImages',{staticClass:"d-block mx-auto mt-3 mb-4",attrs:{"name":"https://res.cloudinary.com/dyf7aejih/image/upload/v1637532709/images/pricing_best_offer_icon_h6wrj6.svg"}}):_vm._e(),_c('div',{staticClass:"card-button d-flex justify-center align-end mx-auto",style:({
            backgroundColor:
              i === 0 ? '#83C4E0' : i === 1 ? '#5AC3B6' : '#9F76E2',
          })},[_c('Texting',{attrs:{"type":"p3bold mywhite"}},[_vm._v("$"+_vm._s(card.price))]),_c('Texting',{staticStyle:{"padding-bottom":"2px"},attrs:{"type":"label7 mywhite"}},[_vm._v("/day")])],1),_c('Texting',{staticClass:"text-center nanum-pen",staticStyle:{"line-height":"14px"},attrs:{"type":"p1 grey23"}},[_vm._v("per campaign")]),(i !== 0)?_c('Texting',{staticClass:"text-center",staticStyle:{"opacity":"0.8","position":"relative"},style:({
            bottom: i === 1 ? '-23px' : '-40px',
          }),attrs:{"type":"label7 grey23"}},[_vm._v(_vm._s(card.discount + "% OFF"))]):_vm._e()],1)])}),_c('v-col',{staticClass:"pt-0 pt-md-8 pb-5",attrs:{"cols":"12"}},[_c('Texting',{staticClass:"text-center",attrs:{"type":"p2n grey23"}},[_vm._v("* You can activate Perkfection for a shorter period than your campaign")])],1)],2),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'indemand'),expression:"type === 'indemand'"}],attrs:{"justify":"center","align":"start"}},[_vm._l((_vm.cards.indemand),function(card,i){return _c('v-col',{key:i,staticClass:"pricing-card pointer mb-8 mb-md-0",class:{
        'ml-5': i !== 0,
        'ind-card-2': i === 1,
        'ind-card-3': i === 2,
        'ind-card-4': i === 3,
      },staticStyle:{"padding":"6px"},style:({
        maxWidth: i === 2 ? '256px' : '202px',
        minWidth: i === 2 ? '256px' : '202px',
        width: i === 2 ? '256px' : '202px',
      }),attrs:{"cols":"3"},on:{"click":function($event){return _vm.$emit('open-signup')}}},[_c('div',{staticClass:"card-container",class:{
          'card4-background': i === 1 || i === 3,
          'card5-background': i === 2,
          'card1-ind-background': i === 0,
        },style:({
          padding:
            i === 0
              ? '26px 29px 25px'
              : i === 2
              ? '14px 56px 37px'
              : '36px 29px 25px',
        })},[(i === 2)?_c('MyImages',{staticClass:"d-block mx-auto mb-5",attrs:{"name":"https://res.cloudinary.com/dyf7aejih/image/upload/v1637564007/images/pricing_bestseller_icon_fleisb.svg"}}):_vm._e(),_c('Heading',{staticClass:"text-center",attrs:{"type":"h4-1 MainDark"}},[_vm._v(_vm._s(card.title))]),_c('Texting',{staticClass:"text-center mb-3 nanum-pen",staticStyle:{"margin-top":"2px"},attrs:{"type":"label0 grey23"}},[_vm._v("subscription")]),_c('div',{staticClass:"card-button mb-2 d-flex justify-center align-end mx-auto",class:{
            'three-month-button': i === 2,
          },style:({
            backgroundColor:
              i === 0
                ? '#64BCE1'
                : i === 1
                ? '#5AC3B6'
                : i === 2
                ? '#9E33E0'
                : '#3DCD99',
          })},[_c('Texting',{attrs:{"type":"p3bold mywhite"}},[_vm._v("$"+_vm._s(card.price))]),_c('Texting',{staticStyle:{"padding-bottom":"2px"},attrs:{"type":"label7 mywhite"}},[_vm._v("/day")])],1),_c('Texting',{staticClass:"text-center nanum-pen",attrs:{"type":"label0 grey23"}},[_vm._v("per campaign")]),(i !== 0)?_c('Texting',{staticClass:"text-center",class:{
            'three-month-discount': i === 2,
          },staticStyle:{"opacity":"0.8","position":"relative"},style:({
            bottom: i === 2 ? '-38px' : '-25px',
          }),attrs:{"type":"label7 package-4-ind-color"}},[_vm._v(_vm._s(card.discount + "% OFF"))]):_vm._e()],1)])}),_c('v-col',{staticClass:"pt-0 pt-md-8 pb-5",attrs:{"cols":"12"}},[_c('Texting',{staticClass:"text-center",staticStyle:{"font-style":"italic"},attrs:{"type":"p2n grey23"}},[_vm._v("** Change your plan or cancel subscription at any time")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }